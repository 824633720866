import React, { useEffect } from "react";
import type { HeadFC } from "gatsby";
import { navigate } from "gatsby";
import { useSelector } from 'react-redux';
import { IState } from "../redux/@types";
import { Helmet } from "react-helmet";


const IndexPage = () => {
    const user = useSelector((state: IState) => state.cognito.user);

    useEffect(() => {
        if (typeof window === "undefined") return;
        // If logged in, redirect to home
        if (user) {
            navigate(`/app`);
            return;
        }
        else {
            navigate(`/login`);
            return;
        }
    }, []);

    // No index page
    return (
        <title>Assess Threat</title>
    )
}

export default IndexPage

export const Head: HeadFC = () => (
    <Helmet>
        <link rel="icon" href="/favicon.ico" />
        <title>Assess Threat</title>
    </Helmet>
)
